import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import BACKGROUND_IMG from '../../../assets/images/background.png';
import LOGO_DR from '../../../assets/images/logo.png';
import PEOPLE_ICON from '../../../assets/images/people_icon.png';

const styles = {
    wrapper: {
        background: 'linear-gradient(0deg, rgba(245, 250, 255, 0.30) -3.22%, #EDF6FF 78%)',
        padding: '12px 60px',
        height: '100vh',
        paddingTop: '50px',
        '@media (max-width: 768px)': {
            padding: '12px 16px',
            paddingTop: '100px',
        },
    },
    main_header: {
        // maxWidth: "1128px",
        width: '100%',
        margin: '0 auto',
        padding: '24px 0',
        backgroundImage: `url(${BACKGROUND_IMG})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        zIndex: '100',
        height: '96px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        // "@media (min-width: 1232px)": {
        //   paddingLeft: "380px",
        //   paddingRight: "380px",
        // },
        // "@media (min-width: 768px) and (max-width: 1231px)": {
        //   paddingLeft: "52px",
        //   paddingRight: "52px",
        // },
    },
    header_wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        maxWidth: '1128px',
        width: '100%',
        zIndex: '100',
    },
    logo_container: {
        height: '40px',
        width: '154px',
        '&> img': {
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'cover',
        },
    },
    gruop_btn_header_wrapper: {
        display: 'flex',
    },
    box_btn_header_login: {
        marginRight: '12px',
    },
    box_btn_header_register: {
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    btn_icon_img: {
        marginRight: '4px',
        marginTop: '-9px',
        height: '20px',
        width: '20px',
        '& > img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        },
    },
    btn_header_login: {
        height: '48px',
        width: '107px',
        fontWeight: '500',
        color: '#466888',
    },
    btn_header_register: {
        height: '48px',
        width: '124px',
        borderRadius: '4px',
        fontWeight: '500',
        backgroundColor: '#FFFFFF',
        color: '#2C62D6',
        '&:hover': {
            opacity: '0.8',
            backgroundColor: '#FFFFFF',
        },
    },
    container: {
        maxWidth: '1128px',
        width: '100%',
        margin: '0 auto',
        marginBottom: '96px',
        '@media (max-width: 768px)': {
            marginBottom: '40px',
        },
    },
    content: {
        display: 'flex',
        marginTop: '60px',
        '@media (max-width: 768px)': {
            marginTop: '12px',
        },
    },
    content_left: {
        flex: 1,
        marginTop: '90px',
        paddingRight: '10px',
        '@media (max-width: 960px)': {
            marginTop: '22px',
        },
    },
    main_heading: {
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '52px',
        letterSpacing: '0em',
        textAlign: 'left',
        '@media (max-width: 1200px)': {
            fontSize: '36px',
            lineHeight: '46px',
        },

        '@media (max-width: 960px)': {
            fontSize: '28px',
            lineHeight: '32px',
        },
    },
    name_brand: {
        fontWeight: '700',
        color: '#2C62D6',
    },
    name_brand_tittle: {
        fontWeight: '700',
        color: '#152953',
        fontSize: '24px',
        whiteSpace: 'pre-wrap',
        '@media (max-width: 960px)': {
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: '600',
        },
    },
    name_brand_sub_tittle: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#466888',
        marginTop: '28px',
        whiteSpace: 'pre-wrap',
        '@media (max-width: 1200px)': {
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '-0.1px',
        },
        '@media (max-width: 960px)': {
            fontSize: '14px',
            lineHeight: '20px',
            marginTop: '18px',
            '& > br': {
                display: 'none',
            },
        },
    },
    gruop_btn_hero_wrapper: {
        marginTop: '46px',
    },
    main_btn_hero: {
        display: 'flex',
        '@media (min-width: 735px)': {
            display: 'none',
        },
    },
    btn_item: {
        height: '50px',
        width: '124px',
        borderRadius: '4px',
        fontWeight: '500',
    },
    box_btn_item: {
        marginRight: '24px',
        '@media (max-width: 768px)': {
            marginRight: 0,
            width: '100%',
        },
    },
    box_btn_white: {
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    btn_item_white: {
        backgroundColor: '#FFFFFF',
        color: '#2C62D6',
        '&:hover': {
            opacity: '0.8',
            backgroundColor: '#FFFFFF',
        },
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    btn_item_blue: {
        backgroundColor: '#2C62D6',
        color: '#fff',
        '&:hover': {
            opacity: '0.8',
            backgroundColor: '#2C62D6',
        },
        '@media (max-width: 768px)': {
            marginRight: 0,
            width: '100%',
        },
    },
    contaner_img_banner: {
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '&> img': {
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'cover',
            borderRadius: '8px',
        },
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    link: {
        fontWeight: '500',
        color: '#466888',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    link_black: {
        color: '#fff',
    },
    message: {
        marginTop: '120px',
        textAlign: 'left',
        fontSize: '24px',
    },
    messageTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    messageContent: {
        fontSize: '16px',
        lineHeight: '1.5'
    }
};

function OneTimeRegistered({ classes }) {
    const { t } = useTranslation();

    return (
        <div>
            <div className={classes.main_header}>
                <div className={classes.header_wrapper}>
                    <div className={classes.logo_container}>
                        <a className={classes.link} href="/">
                            <img src={LOGO_DR} alt="LOGO_DR" />
                        </a>
                    </div>
                    <div className={classes.gruop_btn_header_wrapper}>
                            <>
                                <div className={classes.box_btn_header_login}>
                                    <Button className={classes.btn_header_login} color="primary">
                                        <a className={classes.link} href="/doctor/sign-in">
                                            <div className={classes.btn_icon_img}>
                                                <img src={PEOPLE_ICON} alt="PEOPLE_ICON" />
                                            </div>
                                            {t('landing_login')}
                                        </a>
                                    </Button>
                                </div>
                                <div className={classes.box_btn_header_register}>
                                    <Button className={classes.btn_header_register}>
                                        <a className={`${classes.link}`} href="/doctor/sign-up">
                                            {t('landing_register')}
                                        </a>
                                    </Button>
                                </div>
                            </>
                    </div>
                </div>
            </div>
            <div className={classes.wrapper}>
                <div className={classes.message}>
                    <h3 className={classes.messageTitle}>{t('one_time_register.title')}</h3>
                    <div className={classes.messageContent}>
                        <p>{t('one_time_register.content')}</p>
                        <p>{t('one_time_register.content1')}</p>
                        <p>{t('one_time_register.content2')}</p>
                        <p>{t('one_time_register.content3')}</p>
                        <p>{t('one_time_register.content4')}</p>
                        <p>{t('one_time_register.content5')}</p>
                        <p>{t('one_time_register.content6')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

OneTimeRegistered.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(OneTimeRegistered);
