import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import DoctorPlatformTheme from '../components/common/DoctorPlatformTheme';
import { DOCTOR_PLATFORM_LOGO } from '../shared/Constants';
import SignInForm from '../components/sign-in/SignInForm';
import ForgotPassword from '../components/sign-in/ForgotPassword';
import VerifyForgotPasswordCode from '../components/sign-in/VerifyForgotPasswordCode';
import CreateNewPassword from '../components/sign-in/CreateNewPassword';
import VerifyLogin from '../components/sign-in/VerifyLogin';
import Footer from '../components/common/Footer';
import useResize from '../hooks/useResize';
import useGetHeigthClient from '../hooks/useGetHeigthClient';

const styles = theme => ({
  main: {
    height: 'calc(100vh - 42px)',
    width: '100%',
    background: 'linear-gradient(0deg, rgba(245, 250, 255, 0.30) -3.22%, #EDF6FF 78%)',
    '@media (max-width: 768px)': {
      height: '100%',
    },
  },
  container: {
    display: 'flex',
    height: '100%',
    paddingBottom: theme.spacing(6),
    '@media (min-width:1366px)': {
      maxWidth: '1442px',
    },
  },
  logo_container: {
    margin: '58px 0 42px 0',
    '@media (max-width:320px)': {
      margin: '24px 0',
    },
  },
  container_main_layout: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '136px',
    '@media (min-width:1200px) and (heigth: 1024px)': {
      height: 'calc(100vh - 556px)',
    },
    '@media screen and (max-width: 768px)': {
      marginBottom: 0,
    },
  },
  logo: {
    width: 'auto',
    marginBottom: '16px',
    cursor: 'pointer',
  },
  mainGrid: {
    textAlign: 'center',
    display: 'grid',
    placeItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mainSlider: {
    display: 'grid',
    placeItems: 'center',
  },
  mainSubTitle: {
    display: 'grid',
    placeItems: 'center',
  },
  hidden: {
    '@media (max-width:1200px)': {
      display: 'none',
    },
  },
  ipadPro_sign_in: {
    '@media screen and (min-width:1336px)': {
      height: '100% !important',
    },
  },
  main_form: {
    '@media screen and (min-width:768px) and (max-width:1024px)': {
      height: '60%',
      margin: 'auto',
    },
  },
});

const actionAttributes = (location, enqueueSnackbar, setIsLoading, verifyLogin, confirmedResetPassword, email) => {
  const attributes = {
    renderComponent: <SignInForm enqueueSnackbar={enqueueSnackbar} email={email} />,
  };

  if (verifyLogin) {
    attributes.renderComponent = (
      <VerifyLogin
        enqueueSnackbar={enqueueSnackbar}
        setIsLoading={setIsLoading}
      />
    );
  }

  if (confirmedResetPassword) {
    attributes.renderComponent = (
      <CreateNewPassword
        enqueueSnackbar={enqueueSnackbar}
        setIsLoading={setIsLoading}
      />
    );
  } else {
    switch (location.pathname) {
      case '/doctor/password/new':
        attributes.renderComponent = (
          <ForgotPassword
            enqueueSnackbar={enqueueSnackbar}
            setIsLoading={setIsLoading}
          />
        );
        break;
      case '/doctor/password/edit':
        attributes.renderComponent = (
          <VerifyForgotPasswordCode
            enqueueSnackbar={enqueueSnackbar}
            setIsLoading={setIsLoading}
          />
        );
        break;
      default:
    }
  }

  return attributes;
};

const SignInProcess = (props) => {
  const {
    classes, enqueueSnackbar, location,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { reset_password_info: resetPasswordInfo, verify_login_mfa: verifyLoginMFA } = window.gon;
  let confirmedResetPassword = false;
  let verifyLogin = false;
  if (verifyLoginMFA) {
    verifyLogin = verifyLoginMFA.value || false;
  }
  if (!_isEmpty(resetPasswordInfo)) {
    confirmedResetPassword = resetPasswordInfo.confirmed_reset_password_code;
  }

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const {
    renderComponent,
  } = actionAttributes(location, enqueueSnackbar, setIsLoading, verifyLogin || false, confirmedResetPassword || false, email);

  const width = useResize();
  const heigth = useGetHeigthClient();
  const isSignIn = location.pathname === '/doctor/sign-in';
  const ref = useRef();

  useEffect(() => {
    if (!isSignIn) {
      ref.current.style.height = '100%';
    }
    return () => {
      ref.current.style.height = 'inherit';
    };
  }, []);


  return (
    <MuiThemeProvider theme={DoctorPlatformTheme}>
      <div className={classes.main} ref={ref} style={(isSignIn && heigth >= 800) ? { height: '100%' } : { height: 'auto' }}>
        <Container className={`${classes.container} ${(width === 1366 && isSignIn) ? classes.ipadPro_sign_in : ''}`}>
          <Grid container alignItems="center" justifyContent="center" className={classes.main_form}>
            {(isSignIn) && (
              <div className={classes.logo_container}>
                <img
                  alt="App Logo"
                  src={DOCTOR_PLATFORM_LOGO}
                  className={classes.logo}
                  onClick={() => window.location.replace('/')}
                />
              </div>
            )}
            <div className={classes.container_main_layout}>
              <Grid
                container
                item
                xs={12}
                className={classes.mainGrid}
              >
                {
                  (isSignIn) ? <></> : (
                    <img
                      alt="App Logo"
                      src={DOCTOR_PLATFORM_LOGO}
                      className={classes.logo}
                    />

                  )
                }
                {renderComponent}
              </Grid>
            </div>
          </Grid>
        </Container>
        <Footer isFixed />
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </MuiThemeProvider>
  );
};

SignInProcess.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(withSnackbar(SignInProcess));
