import React from 'react';
import AboutComponent from '../components/about/About';

function About() {
  return (
    <>
      <AboutComponent />
    </>
  );
}

export default About;
