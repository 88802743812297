import { useState, useEffect } from 'react';

function useGetHeigthClient() {
  const getHeigth = () => window.innerHeight || document.documentElement.clientHeight;
  const [heigth, setHeigth] = useState(getHeigth());

  useEffect(() => {
    const resizeListener = () => {
      setHeigth(getHeigth());
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return heigth;
}

export default useGetHeigthClient;
