import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import LOGO_DR from '../../../assets/images/logo.png';

const styles = (theme) => ({
  wrapper: {
    marginTop: '80px',
    padding: '24px 120px',
    [theme.breakpoints.down('md')]: {
      marginTop: '0',
      padding: '24px 40px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '12px 16px',
    },
  },
  footerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '16px',
    },
  },
  logo_container: {
    height: '44px',
    width: '100px',
    '&> img': {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
    [theme.breakpoints.down('md')]: {
      height: '38px',
      width: '133px',
    },
  },
  group_links: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '40px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      flexDirection: 'row',
      gap: '24px',
      margin: 'auto'
    },
  },
  link_item: {
    color: '#4F4E57',
    fontFamily: 'Hiragino Sans, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    padding: '8px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
});
function FooterIntroduction({ classes }) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.footerContainer}>
        <div className={classes.logo_container}>
          <img src={LOGO_DR} alt="LOGO_DR" />
        </div>
        <div className={classes.group_links}>
          <a href="/about" className={classes.link_item}>Dr.Benriとは</a>
          <a href="mailto:contact@drbenri.com" className={classes.link_item}>お問い合わせ</a>
        </div>
      </div>
    </div>
  );
}

FooterIntroduction.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(FooterIntroduction);
