import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = () => ({
  submit: {
    borderRadius: 5,
    width: 'auto',
  },
});

const SubmitButton = ({
  classes, isValid, text, ...rest
}) => (
  <Button
    {...rest}
    size="small"
    type="submit"
    variant="contained"
    color="primary"
    className={classes.submit}
    disabled={!isValid}
  >
    {text}
  </Button>
);

SubmitButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  isValid: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

SubmitButton.defaultProps = {
  isValid: true,
};

export default withStyles(styles)(SubmitButton);
