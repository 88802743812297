export default (newEle, currentEles) => {
  const existedIndex = currentEles.indexOf(newEle);
  let newEles = [];
  if (existedIndex === -1) {
    newEles = newEles.concat(currentEles, newEle);
  } else if (existedIndex === 0) {
    newEles = newEles.concat(currentEles.slice(1));
  } else if (existedIndex === currentEles.length - 1) {
    newEles = newEles.concat(currentEles.slice(0, -1));
  } else if (existedIndex > 0) {
    newEles = newEles.concat(
      currentEles.slice(0, existedIndex),
      currentEles.slice(existedIndex + 1),
    );
  }
  return newEles;
};
