import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useLocation } from 'react-router';
import qs from 'qs';
import _isNull from 'lodash/isNull';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ReactCodeInput from 'react-verification-code-input';
import PushSnackbarMessage from '../../helpers/PushSnackbarMessage';
import AxiosHeaders from '../../helpers/AxiosHeaders';
import linkWithLang from '../../helpers/LinkWithLanguage';
import getCurrentLanguage from '../../helpers/GetCurrentLanguage';

const styles = theme => ({
  root: {
    minWidth: 275,
    maxWidth: 500,
    maxHeight: window.innerHeight - theme.spacing(22),
    padding: theme.spacing(2),
    overflowY: 'scroll',
  },
  des: { marginTop: theme.spacing(2) },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    margin: theme.spacing(2),
    fontSize: '2rem',
  },
  confirmText: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(7),
  },
  btn: { textAlign: 'right' },
  email: { marginLeft: theme.spacing(1) },
  backBtn: {
    '&:hover': {
      background: 'none',
      color: '#000',
    },
    marginBottom: theme.spacing(3),
  },
  tryAgain: {
    '&:hover': { cursor: 'pointer' },
  },
  containerGrid: {
    marginTop: theme.spacing(2),
  },
  reactCodeInput: {
    margin: 'auto',
  },
});

const CreateAccountForm = (props) => {
  const {
    classes, enqueueSnackbar, setIsLoading,
  } = props;
  const { email: doctorEmail } = window.gon.registration_info;
  const [confirmCode, setConfirmCode] = useState(null);
  const location = useLocation();
  const { t } = useTranslation();

  const lang = getCurrentLanguage();

  const enableSubmit = (
    !_isNull(confirmCode) && confirmCode.length === 4
  );

  const handleVerifyCode = () => {
    const {
      confirmation_token: confirmationToken,
    } = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (enableSubmit) {
      const submitData = Object.assign({});
      submitData.confirmation_token = confirmationToken;
      submitData.confirmation_code = confirmCode;
      axios({
        url: location.pathname,
        method: 'GET',
        headers: AxiosHeaders,
        params: submitData,
      }).then(() => {
        PushSnackbarMessage(enqueueSnackbar, t('Email registered successfully'), 'success');
        window.location.href = linkWithLang(`${location.pathname}?confirmation_token=${confirmationToken}`);
      }).catch(() => {
        PushSnackbarMessage(enqueueSnackbar, t('The code is not correct'), 'error');
        setConfirmCode(null);
      });
    } else {
      PushSnackbarMessage(enqueueSnackbar, t('The code must includes 4 numbers!'), 'error');
    }
  };
  const handleResendEmail = () => {
    if (!_isNull(doctorEmail)) {
      setIsLoading(true);
      axios({
        url: 'confirmation',
        method: 'POST',
        headers: AxiosHeaders,
        data: { doctor: { email: doctorEmail } },
      }).then(() => {
        if (lang === 'en') {
          PushSnackbarMessage(enqueueSnackbar, t('Email has been resent.'), 'success');
        } else {
          PushSnackbarMessage(enqueueSnackbar, 'メールが再送されました。', 'success');
        }
        setIsLoading(false);
      }).catch(() => {
        PushSnackbarMessage(enqueueSnackbar, t('Unable to resend email.'), 'error');
        setIsLoading(false);
      });
    }
  };
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isJapanSite = params.lng === 'jp';
  const isEnglishSite = params.lng === 'en';

  const renderTermAndPolicy = () => {
    if (isEnglishSite) {
      return (
        <>
          {' Please accept the Dr. Benri '}
          <a href="/Dr_Benri_Terms_of_Use.pdf" target="blank">{t('sign_up.terms_of_service')}</a>
          {t(' and ')}
          {'Dr. Benri'}
          {' '}
          <a href="/Dr_Benri_Privacy_Policy.pdf" target="blank">{t('sign_up.privacy_policy')}</a>
          {' '}
          {'by clicking the "Next" button.'}
          {' '}
          {'Dr. Benri information mail service is also automatically registered by registering as a Dr. Benri member. After registering as a member, you can change the contents of the mail service.'}
        </>
      );
    }

    if (isJapanSite) {
      return (
        <>
          {' Dr. Benriの '}
          <a href="/Dr_Benri_Terms_of_Use.pdf" target="blank">{t('sign_up.terms_of_service')}</a>
          {' および '}
          {' '}
          <a href="/Dr_Benri_Privacy_Policy.pdf" target="blank">{t('sign_up.privacy_policy')}</a>
          {' '}
          {'に同意のうえ、'}
          <br />
          「次へ」ボタンをクリックしてください。
          <br />
          Dr. Benri会員登録をされることにより
          <br />
          Dr. Benriの情報メールサービスへも自動登録されます。
          <br />
          会員登録後にメールサービス内容の変更手続きができます。
        </>
      );
    }
    return null;
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <IconButton aria-label="delete" size="small" className={classes.backBtn} href="/doctor/sign-up">
          <ArrowBackIcon fontSize="inherit" />
          <Typography variant="body2" className={classes.email}>{doctorEmail}</Typography>
        </IconButton>
        <Typography variant="h5" component="h2">
          {t('Verify email')}
        </Typography>
        <Typography variant="body2" className={classes.des} style={{ whiteSpace: 'pre-wrap' }}>
          {t('sign_up.verify_intro')}
          {' '}
          <Link className={classes.tryAgain} onClick={handleResendEmail}>{t('try again')}</Link>
          {isJapanSite && 'をクリックしてください。'}
        </Typography>
        <Grid container spacing={3} className={classes.containerGrid}>
          <Grid item xs={12}>
            <ReactCodeInput
              fields={4}
              autoFocus
              className={classes.reactCodeInput}
              onChange={(vals) => {
                setConfirmCode(vals);
              }}
            />
          </Grid>
        </Grid>
        <Typography variant="body2" className={classes.confirmText}>
          {
            renderTermAndPolicy()
          }
        </Typography>
        <div className={classes.btn}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleVerifyCode}
            disabled={!enableSubmit}
          >
            {t('Agree')}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

CreateAccountForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default withStyles(styles)(CreateAccountForm);
