import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';
import _isNull from 'lodash/isNull';

const styles = theme => ({
  root: { marginBottom: theme.spacing(2) },
  chipArray: {
    marginTop: theme.spacing(2),
  },
  sub_spe_limit: {
    fontSize: 12,
  },
  chipElement: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    background: 'rgba(4,98, 214, 0.1)',
    fontSize: 14,
  },
  deleteIcon: {
    fill: 'rgba(4,98, 214, 0.36)',
  },
  required: {
    float: 'right',
    marginLeft: '5px',
    color: 'red',
    fontSize: '12px',
  },
});

const DiseaseSpecialitiesField = (props) => {
  let {
    classes, options, handleChangeWorkExperienceInfos, diseaseSpecialities, diseaseSpecialitiesRef,
  } = props;
  const [specialityList, setSpecialityList] = useState(options);
  const [specialityValue, setSpecialityValue] = useState({});
  const [subSpecialityList, setSubSpecialityList] = useState([]);
  const [defaulftSubSpecialityList, setDefaulftSubSpecialityList] = useState([]);

  useEffect(() => {
    if (diseaseSpecialities.length > 0) {
      const currentDiseaSpecialities = diseaseSpecialities[0];
      const index = options.findIndex(spec => spec.id === currentDiseaSpecialities.id);
      setSpecialityValue(currentDiseaSpecialities);
      setSubSpecialityList(currentDiseaSpecialities.sub_speciality);
      setDefaulftSubSpecialityList(options[index].sub_speciality);
    } else {
      setSpecialityValue({});
      setSubSpecialityList([]);
      setDefaulftSubSpecialityList([]);
    }
  }, [diseaseSpecialities[0]]);

  const { t } = useTranslation();

  const handleChangeSpecialites = (value) => {
    if (_isNull(value)) return;
    setSpecialityValue(value);
    const currentId = value.id;
    const newValue = value.inputValue || value.name || value || '';
    const newSubSpecility = value;
    const newList = [
      {
        id: currentId,
        name: newValue,
        sub_speciality: [],
      },
    ];
    const listSubSpecility = [...newSubSpecility.sub_speciality];
    if (_isArray(listSubSpecility)) {
      setDefaulftSubSpecialityList(listSubSpecility);
    }

    if (!_includes(specialityList.map(e => e.name), newValue)) {
      specialityList.push({ name: newValue });
      setSpecialityList(specialityList);
    }
    handleChangeWorkExperienceInfos(newList, 'diseaseSpecialities');
  };

  const handleDeleteSubSpeciality = (e, subSpeciality) => {
    const currentSubSpeciality = [...subSpecialityList];
    const newList = currentSubSpeciality.filter(item => item !== subSpeciality);
    setSubSpecialityList(newList);
    const preSubSpeciality = [...diseaseSpecialities];
    preSubSpeciality[0].sub_speciality = newList;
    handleChangeWorkExperienceInfos(preSubSpeciality, 'diseaseSpecialities');
  };

  const handleChangeSubSpecialites = (value) => {
    if (_isNull(value)) return;
    const currentSubSpeciality = [...subSpecialityList];
    const checkExistedSubSpeciality = currentSubSpeciality.some(subItem => subItem.id === value.id);
    if (checkExistedSubSpeciality) return;
    currentSubSpeciality.push(value);
    if (currentSubSpeciality.length > 6) {
      currentSubSpeciality.splice(5, 1);
    }
    setSubSpecialityList(currentSubSpeciality);
    const preSubSpeciality = [...diseaseSpecialities];
    preSubSpeciality[0].sub_speciality = currentSubSpeciality;
    handleChangeWorkExperienceInfos(preSubSpeciality, 'diseaseSpecialities');
  };

  return (
    <div className={classes.root}>
      <div className="form-group">
        <label htmlFor="diseaseSpecialities">
          {`${t('Disease specialities')}*`}
        </label>
        <Autocomplete
          options={specialityList}
          getOptionLabel={(option) => {
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name || '';
          }}
          noOptionsText={t('No options')}
          getOptionSelected={option => option.id === specialityValue.id}
          renderOption={option => option.name}
          size="small"
          renderInput={params => (
            <TextField {...params} placeholder={t('Please Select')} ref={diseaseSpecialitiesRef} required />
          )}
          onChange={(_, value) => handleChangeSpecialites(value)}
          value={specialityValue}
        />
      </div>
      <div className="form-group">
        <label htmlFor="diseaseSpecialities">{`${t('Sub-Specialities')}`}</label>
        <br />
        <p className={classes.sub_spe_limit}>{t('only_six_fields')}</p>
        <Autocomplete
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          defaultValue={[]}
          noOptionsText={t('No options')}
          options={defaulftSubSpecialityList}
          getOptionLabel={(option) => {
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name || '';
          }}
          renderOption={option => t(option.name)}
          size="small"
          renderInput={params => (
            <TextField {...params} placeholder={t('Please Select')} />
          )}
          onChange={(_, value) => handleChangeSubSpecialites(value)}
          getOptionSelected={option => subSpecialityList.some(
            subItem => subItem?.id === option?.id,
          )}
        />
      </div>
      <div className={classes.chipArray}>
        {subSpecialityList.map((speciality) => {
          if (_isNull(speciality)) return <></>;
          const key = `speciality-${speciality.id}`;

          return (
            <Chip
              key={key}
              label={speciality.name}
              onDelete={e => handleDeleteSubSpeciality(e, speciality)}
              className={classes.chipElement}
              deleteIcon={<CancelIcon className={classes.deleteIcon} />}
              style={{ maxWidth: 200 }}
            />
          );
        })
        }
      </div>
    </div>
  );
};

DiseaseSpecialitiesField.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  handleChangeWorkExperienceInfos: PropTypes.func.isRequired,
  diseaseSpecialities: PropTypes.instanceOf(Array).isRequired,
};

export default withStyles(styles)(DiseaseSpecialitiesField);
