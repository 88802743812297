import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import qs from 'qs';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import _isArray from 'lodash/isArray';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import PushSnackbarMessage from '../../helpers/PushSnackbarMessage';
import AxiosHeaders from '../../helpers/AxiosHeaders';
import linkWithLang from '../../helpers/LinkWithLanguage';

const styles = theme => ({
  root: {
    minWidth: 275,
    maxWidth: 500,
    maxHeight: window.innerHeight - theme.spacing(21),
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  des: { marginTop: theme.spacing(2) },
  email: { marginLeft: theme.spacing(1) },
  backBtn: {
    '&:hover': {
      background: 'none',
      color: '#000',
    },
    marginBottom: theme.spacing(3),
  },
  form: { padding: theme.spacing(2) },
  inputLabel: { fontSize: '1rem' },
  purposesOnly: {
    fontSize: 14,
    marginTop: theme.spacing(1 / 2),
    color: '#9b9b9b',
  },
  callAction: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
  required: {
    color: 'red',
    fontSize: '12px',
  },
});

const DoctorGeneralProfile = (props) => {
  const {
    classes, enqueueSnackbar, setIsLoading,
  } = props;
  const {
    registration_info: registrationInfo,
    profile_registration: initialProfileInfos,
    medical_qualification_types: medicalQualificationTypes,
  } = window.gon;
  const { email: doctorEmail } = registrationInfo;
  const [profileInfos, setProfileInfos] = useState(initialProfileInfos);
  const handleChangeProfileInfos = (value, type) => {
    setProfileInfos(prevState => ({ ...prevState, [type]: value }));
  };
  const location = useLocation();
  const { t } = useTranslation();

  const {
    confirmation_token: confirmationToken,
  } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const handleUpdateDoctorProfile = () => {
    setIsLoading(true);
    const {
      id, fname, lname,
      fname_phonetics: fnamePhonetics, lname_phonetics: lnamePhonetics,
      medical_qualification: medicalQualification,
    } = profileInfos;
    if (!_isNull(id)) {
      const submitData = Object.assign({});
      if (!_isEmpty(fname)) {
        submitData.fname = fname;
      }
      if (!_isEmpty(lname)) {
        submitData.lname = lname;
      }
      if (!_isEmpty(fnamePhonetics)) {
        submitData.fname_phonetics = fnamePhonetics;
      }
      if (!_isEmpty(lnamePhonetics)) {
        submitData.lname_phonetics = lnamePhonetics;
      }
      if (!_isEmpty(medicalQualification)) {
        if (medicalQualification === 'Other_Doc') {
          setIsLoading(false);
          PushSnackbarMessage(enqueueSnackbar, t('sign_up.medical_qualification_other'), 'error');
          return;
        }

        submitData.medical_qualification = medicalQualification;
      }

      axios({
        url: linkWithLang(`/doctor/doctors/${profileInfos.id}`),
        method: 'PUT',
        headers: AxiosHeaders,
        data: { doctor: submitData },
      }).then(() => {
        PushSnackbarMessage(enqueueSnackbar, t('Name registered'), 'success');
        setIsLoading(false);
        window.location.href = linkWithLang(`${location.pathname}?confirmation_token=${confirmationToken}`);
      }).catch(({ response: { data } }) => {
        setIsLoading(false);
        if (_isArray(data.message)) {
          data.message.map(me => PushSnackbarMessage(enqueueSnackbar, me, 'error'));
        } else {
          PushSnackbarMessage(enqueueSnackbar, data.message, 'error');
        }
      });
    } else {
      setIsLoading(false);
      PushSnackbarMessage(enqueueSnackbar, t('User ID not recognized'), 'error');
    }
  };
  const enableSubmit = (
    !_isEmpty(profileInfos.fname) && !_isEmpty(profileInfos.lname)
    && !_isEmpty(profileInfos.medical_qualification) && !_isEmpty(profileInfos.fname_phonetics) && !_isEmpty(profileInfos.lname_phonetics)
  );
  const handleBackVerifyCode = () => {
    window.location.href = linkWithLang(`${location.pathname}?confirmation_token=${confirmationToken}&&step=0`);
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <IconButton aria-label="delete" size="small" className={classes.backBtn} onClick={handleBackVerifyCode}>
          <ArrowBackIcon fontSize="inherit" />
          <Typography variant="body2" className={classes.email}>{doctorEmail}</Typography>
        </IconButton>
        <Typography variant="h5" component="h2">
          {t('New Registration')}
        </Typography>
        <Typography variant="body2" className={classes.des}>
          {t('sign_up.intro_edit_profile')}
        </Typography>
        <Typography variant="body2" className={classes.des}>
          <em className={classes.required}>{t('* Required item')}</em>
        </Typography>
      </CardContent>
      <div className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="email" className={classes.inputLabel}>
                {t('Last Name ')}
              </InputLabel>
              <Input
                id="last name"
                value={profileInfos.lname || ''}
                onChange={e => handleChangeProfileInfos(e.target.value, 'lname')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="email" className={classes.inputLabel}>
                {t('First Name ')}
              </InputLabel>
              <Input
                id="first name"
                value={profileInfos.fname || ''}
                onChange={e => handleChangeProfileInfos(e.target.value, 'fname')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="email" className={classes.inputLabel}>
                {t('Last Name (Phonetics)')}
              </InputLabel>
              <Input
                value={profileInfos.lname_phonetics || ''}
                onChange={e => handleChangeProfileInfos(e.target.value, 'lname_phonetics')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="email" className={classes.inputLabel}>
                {t('First Name (Phonetics)')}
              </InputLabel>
              <Input
                value={profileInfos.fname_phonetics || ''}
                onChange={e => handleChangeProfileInfos(e.target.value, 'fname_phonetics')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={medicalQualificationTypes}
              getOptionLabel={option => t(option)}
              size="small"
              disableClearable
              noOptionsText={t('No options')}
              renderInput={params => (
                <TextField {...params} label={t('Medical Qualification')} required />
              )}
              onChange={(_, value) => handleChangeProfileInfos(value, 'medical_qualification')}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.callAction}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleUpdateDoctorProfile}
          disabled={!enableSubmit}
        >
          {t('Next')}
        </Button>
      </div>
    </Card>
  );
};

DoctorGeneralProfile.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default withStyles(styles)(DoctorGeneralProfile);
