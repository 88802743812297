import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  root: {
    background: '#152953',
    fontFamily: 'Hiragino Sans, sans-serif !important',
    width: '100%',
    fontSize: 14,
    lineHeight: '20px',
    padding: '16px 40px',
    '@media screen and (max-width: 576px)': {
      display: 'none',
    },
  },
  links: {
    flex: '1',
    margin: 'auto',
    '&>*': {
      color: '#BDBDBD',
      marginRight: '12px',
      '&:hover': { color: '#fafafa' },
      flex: '1',
    },
    '@media screen and (max-width: 768px)': {
      flexDirection: 'column-reverse',
      alignItems: 'center !important',
      justifyContent: 'center',
      display: 'flex',
      '&> a': {
        marginBottom: 6,
      },
    },
  },
  copyRight: {
    fontSize: 13,
    margin: 'auto',
    color: '#BDBDBD',
  },
  wrap: {
    width: '94%',
    margin: 'auto',
    display: 'flex',
    '@media screen and (max-width: 768px)': {
      flexDirection: 'column-reverse',
    },
  },
  switchLang: {
    color: '#BDBDBD',
    marginRight: '10px',
  },
  left_content: {
    display: 'flex',
    '@media screen and (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center !important',
      justifyContent: 'center',
      marginBottom: 6,
      '&> div': {
        marginBottom: 6,
      },
    },
  },
});

const Footer = ({ classes, isFixed = false }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root} style={isFixed ? { position: 'fixed', bottom: 0 } : {}}>
      <div className={classes.wrap}>
        <div className={`${classes.links} resize-links-mobile`}>
          <Link href="/Dr_Benri_Privacy_Policy.pdf" target="blank">{t('Privacy and Cookies') }</Link>
          <Link href="/Dr_Benri_Terms_of_Use.pdf" target="blank">{t('Legal') }</Link>
        </div>
        <div className={classes.left_content}>
          <div className="align-items-center d-flex">
            <Link className={classes.switchLang} href="mailto:contact@drbenri.com">お問い合わせ</Link>
          </div>
          <Typography variant="body2" className={classes.copyRight}> &copy;2024 Dr.Benri</Typography>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  isFixed: PropTypes.bool,
};

Footer.defaultProps = {
  isFixed: false,
};

export default withStyles(styles)(Footer);
