import React, { Suspense, lazy } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const SectionWelcome = lazy(() => import('./SectionWelcome'));
const SectionArticle = lazy(() => import('./SectionArticle'));
const SectionGuide = lazy(() => import('./SectionGuide'));
const FooterIntroduction = lazy(() => import('./FooterIntroduction'));
const SectionFeature = lazy(() => import('./SectionFeature'));
const SectionVideo = lazy(() => import('./SectionVideo'));

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  content: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
  },
  loadingPlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    fontSize: '18px',
    color: '#666',
  },
}));

function Introduction({ data }) {
  const classes = useStyles();
  const arr = data || window.gon.initLandingIntroduction;
  const dataSectionWelcome = arr.find(sec => sec.name === 'welcome');
  const dataSectionArticle = arr.find(sec => sec.name === 'news');
  const dataSectionGuide = arr.find(sec => sec.name === 'started');

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Suspense fallback={<div className={classes.loadingPlaceholder}>Loading...</div>}>
          <SectionWelcome data={dataSectionWelcome} />
          <SectionFeature />
          <SectionVideo />
          <SectionArticle data={dataSectionArticle} />
          <SectionGuide data={dataSectionGuide} />
          <FooterIntroduction />
        </Suspense>
      </div>
    </div>
  );
}

Introduction.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default Introduction;