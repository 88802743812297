import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import _isArray from 'lodash/isArray';
import DoctorCard from '../../../assets/images/doctor_card.png';
import PushSnackbarMessage from '../../helpers/PushSnackbarMessage';
import UpdateArray from '../../helpers/UpdateArray';
import AxiosHeaders from '../../helpers/AxiosHeaders';
import linkWithLang from '../../helpers/LinkWithLanguage';
import GetCurrentLanguage from '../../helpers/GetCurrentLanguage';

const styles = theme => ({
  root: {
    minWidth: 275,
    maxWidth: 500,
    maxHeight: window.innerHeight - theme.spacing(21),
    overflowY: 'scroll',
    textAlign: 'center',
    padding: '0 16px',
    [theme.breakpoints.down(theme.breakpoints.values.ip5)]: { height: 300 },
    [theme.breakpoints.between(
      theme.breakpoints.values.ip5, theme.breakpoints.values.ipx,
    )]: { height: 420 },
    [theme.breakpoints.between(
      theme.breakpoints.values.ipx, theme.breakpoints.values.iplush,
    )]: { height: 500 },
  },
  des: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '@media screen and (max-width:500px)': {
      fontSize: '11px',
    },
  },
  input: { display: 'none' },
  callAction: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
  skipBtn: {
    width: 114,
    height: 44,
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
  deleteBtn: {
    margin: 'auto',
    marginRight: 0,
    padding: 0,
  },
  filesUpload: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&>*': {
      display: 'flex',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  fileName: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    overflow: 'auto',
  },
  uploadBtn: { width: '100%' },
});

const DoctorPassportUpload = (props) => {
  const { classes, enqueueSnackbar, setIsLoading } = props;
  const [doctorPassports, setDoctorPassports] = useState([]);

  const { t } = useTranslation();

  const handleSelectPassports = (event) => {
    setIsLoading(true);
    const { files } = event.target;
    const newDoctorPassports = [...doctorPassports];
    Object.keys(files).forEach((key) => {
      const file = files[key];
      const fileSize = file.size;

      if (fileSize >= 5242880) {
        PushSnackbarMessage(enqueueSnackbar, `${file.name} is larger than 5MB!`, 'error');
      } else if (doctorPassports.map(dp => dp.name).includes(file.name)) {
        PushSnackbarMessage(enqueueSnackbar, `${file.name} is selected!`, 'error');
      } else {
        newDoctorPassports.push(file);
      }
    });
    setIsLoading(false);
    setDoctorPassports(newDoctorPassports);
  };
  const handleRemovePassport = (passport) => {
    setDoctorPassports(UpdateArray(passport, doctorPassports));
  };
  const handleUploadLicenseCard = (action) => {
    const { id: currentDoctorId } = window.gon.account_info;
    setIsLoading(true);
    const submitData = new FormData();
    submitData.append('doctor_license_card[doctor_id]', currentDoctorId);
    if (_isArray(doctorPassports) && doctorPassports.length > 0) {
      doctorPassports.forEach((passport) => {
        submitData.append('doctor_license_card[card_images][]', passport);
      });
    }

    axios({
      url: linkWithLang('/doctor/doctor_license_cards'),
      method: 'POST',
      headers: AxiosHeaders,
      data: submitData,
    }).then((response) => {
      if (response.status === 201 && action === 'upload') {
        PushSnackbarMessage(enqueueSnackbar, t('Upload is completed'), 'success');
      }
      window.location.href = linkWithLang('/pending');
    }).catch(({ res: { data } }) => {
      if (_isArray(data.message)) {
        data.message.map(me => PushSnackbarMessage(enqueueSnackbar, me, 'error'));
      } else {
        PushSnackbarMessage(enqueueSnackbar, data.message, 'error');
      }
    });

    setIsLoading(false);
  };
  const lang = GetCurrentLanguage();

  return (
    <Card className={classes.root}>
      <CardContent>
        <img src={DoctorCard} alt="Doctor Card" className="img-fluid" width="100%"/>
        <Typography variant="h5" component="h2">{t('Optional ID Upload')}</Typography>
        <Typography variant="body2" className={classes.des}>
          {t('Option_verification')}
          <br />
          {lang === 'jp' ? t('Option_verification_1') : '' }
        </Typography>
        <div>
          { !!doctorPassports.length && (
          <div className={classes.filesUpload}>
            {doctorPassports.map((passport, ind) => {
              const { name } = passport;
              const key = `passport-${ind}`;

              return (
                <div key={key}>
                  <AttachFileIcon fontSize="small" />
                  <Typography variant="body2" color="primary" className={classes.fileName}>
                    {name}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    className={classes.deleteBtn}
                    onClick={() => handleRemovePassport(passport)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
              );
            })}
          </div>
          )}

          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={e => handleSelectPassports(e)}
          />
          <label htmlFor="contained-button-file" className={classes.uploadBtn}>
            <Button
              variant="outlined"
              component="span"
              disableElevation
              color="primary"
              fullWidth
              startIcon={<CloudUploadIcon />}
            >
              {t('Browse files')}
            </Button>
          </label>
        </div>
      </CardContent>
      <div className={`${classes.callAction} resize-padding-mobile`}>
        <Button
          size="small"
          variant="contained"
          className={classes.skipBtn}
          disableElevation
          onClick={() => handleUploadLicenseCard('skip')}
        >
          {t('Skip')}
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!(doctorPassports.length > 0)}
          onClick={() => handleUploadLicenseCard('upload')}
        >
          {t('Upload')}
        </Button>
      </div>
    </Card>
  );
};

DoctorPassportUpload.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default withStyles(styles)(DoctorPassportUpload);
