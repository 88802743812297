import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CreateNewPasswordForm from './CreateNewPasswordForm';

const CreateNewPassword = ({ enqueueSnackbar }) => {
  const values = { password: '' };
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    password: Yup.string().required(t('Enter your password'))
      .min(8, t('Password is too short - should be 8 letters minimum'))
      .max(32, t('Password is too long - should be less than 32 letters'))
      .matches(/[a-z]/, t('Password has to contain lowercase letter'))
      .matches(/[A-Z]/, t('Password has to contain uppercase letter'))
      .matches(/[0-9]/, t('Password has to contain number')),
  });

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <CreateNewPasswordForm {...formikProps} enqueueSnackbar={enqueueSnackbar} />
      )}
    </Formik>
  );
};

CreateNewPassword.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default CreateNewPassword;
