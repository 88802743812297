import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Parser from 'html-react-parser';
import SubmitButton from '../common/SubmitButton';
import RegularButton from '../common/RegularButton';
import linkWithLang from '../../helpers/LinkWithLanguage';

const styles = theme => ({
  root: {
    minWidth: 275,
    padding: theme.spacing(2),
    margin: 'auto',
    textAlign: 'left',
    marginTop: theme.spacing(4),
  },
  cardContent: {
    padding: '16px 0',
  },
  des: {
    marginTop: theme.spacing(2),
    '@media (max-width: 768px)': {
      whiteSpace: 'pre-wrap',
    },
  },
  callAction: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    '&>a': { marginRight: theme.spacing(1) },
  },
  form: {
    padding: theme.spacing(2),
    '@media screen and (max-width: 768px)': {
      padding: 0,
    },
  },
  inputLabel: { fontSize: '1rem' },
  formEmail: {
    marginTop: 0,
    marginBottom: '46px',
  },
});

class ForgotPasswordForm extends Component {
  state = {
    formAttrs: {},
    childNodes: '',
  };

  componentDidMount = () => {
    const originalForm = document.getElementById('doctor-forgot-password-form');
    if (!originalForm) { return; }
    const formAttrs = ['action', 'acceptCharset', 'method'].reduce((obj, val) => {
      const transformObj = obj;
      transformObj[val] = originalForm[val];
      return transformObj;
    }, {});
    const childNodes = originalForm.innerHTML;
    this.setState({
      formAttrs,
      childNodes,
    });
  };

  render() {
    const {
      values: { email },
      errors,
      touched,
      handleChange,
      setFieldTouched,
      classes,
      setIsLoading,
      t,
    } = this.props;
    const isValid = () => {
      let valid = false;

      if (!_isEmpty(email)) {
        valid = _isEmpty(errors);
      }
      return valid;
    };
    const hiddenFields = [
      { name: 'doctor[email]', value: email.toUpperCase() },
    ];
    const change = (e, name) => {
      e.persist();
      handleChange(e);
      setFieldTouched(name, true, false);
    };
    const {
      formAttrs, childNodes,
    } = this.state;
    const hasError = field => (touched[field] && Boolean(errors[field]));
    const errorMessage = field => (touched[field] ? errors[field] : '');

    return (
      <form {...formAttrs} className={classes.form}>
        {hiddenFields.map(field => (
          <input
            type="hidden"
            key={field.name}
            name={field.name}
            value={field.value}
          />
        ))}
        <Card className={classes.root}>
          <CardContent className={classes.cardContent}>
            <Typography component="h2" variant="h5">
              {t('Forgot password')}
            </Typography>
            <Typography variant="body2" className={classes.des}>
              セキュリティコードを取得するには、
              メールアドレスを入力してください。
            </Typography>
          </CardContent>
          <div className={classes.form}>
            {Parser(childNodes)}
            <FormControl className={classes.formEmail} margin="normal" required fullWidth>
              <InputLabel htmlFor="email">{t('Email Address')}</InputLabel>
              <Input
                id="email"
                value={email}
                error={hasError('email')}
                onChange={e => change(e, 'email')}
              />
              <FormHelperText>{errorMessage('email')}</FormHelperText>
            </FormControl>
            <div className={classes.callAction}>
              <RegularButton text={t('Cancel')} href={linkWithLang('/doctor/sign-in')} />
              <SubmitButton isValid={isValid()} text={t('Get code')} onClick={() => setIsLoading(true)} />
            </div>
          </div>
        </Card>
      </form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  setIsLoading: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(ForgotPasswordForm));
