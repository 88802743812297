import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import SectionAbout from './SectionAbout';
import FooterIntroduction from '../introductionsLanding/FooterIntroduction';

const styles = {
  wrapper: {
    position: 'relative',
  },
};

function About({ classes }) {
  return (
    <div className={classes.wrapper}>
      <SectionAbout />
      <FooterIntroduction />
    </div>
  );
}

About.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(About);
