import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import Parser from 'html-react-parser';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import RegularButton from '../common/RegularButton';
import SubmitButton from '../common/SubmitButton';
import linkWithLang from '../../helpers/LinkWithLanguage';

const styles = theme => ({
  root: {
    minWidth: 275,
    maxWidth: 500,
    padding: theme.spacing(2),
    margin: 'auto',
    textAlign: 'left',
    marginTop: theme.spacing(4),
  },
  des: { marginTop: theme.spacing(2) },
  callAction: {
    display: 'flex',
    alignItems: 'baseline',
    float: 'right',
    '&>a': { marginRight: theme.spacing(1) },
  },
  form: { padding: theme.spacing(2) },
  inputLabel: { fontSize: '1rem' },
});

class CreateNewPasswordForm extends Component {
  state = {
    showPassword: false,
    formAttrs: {},
    childNodes: '',
  };

  componentDidMount = () => {
    const originalForm = document.getElementById('set-new-password-form');
    if (!originalForm) { return; }
    const formAttrs = ['action', 'acceptCharset', 'method'].reduce((obj, val) => {
      const transformObj = obj;
      transformObj[val] = originalForm[val];
      return transformObj;
    }, {});
    const childNodes = originalForm.innerHTML;
    this.setState({
      formAttrs,
      childNodes,
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const {
      values: { password },
      errors,
      touched,
      handleChange,
      setFieldTouched,
      classes,
      t,
    } = this.props;
    const isValid = () => {
      let valid = false;

      if (!_isEmpty(password)) {
        valid = _isEmpty(errors);
      }
      return valid;
    };

    const { location } = window;
    const {
      reset_password_token: resetPasswordToken,
    } = qs.parse(location.search, { ignoreQueryPrefix: true });

    const hiddenFields = [
      { name: 'doctor[password]', value: password },
      { name: 'reset_password_token', value: resetPasswordToken },
    ];

    const change = (e, name) => {
      e.persist();
      handleChange(e);
      setFieldTouched(name, true, false);
    };

    const {
      showPassword, formAttrs, childNodes,
    } = this.state;

    const hasError = field => (
      (touched[field] && Boolean(errors[field]))
        || (Boolean(errors[field]) && Boolean(!errors.passwordConfirmation))
    );

    const errorMessage = field => (
      (touched[field] || Boolean(!errors.passwordConfirmation)) ? t('Password must have at least 8 letters, contain lowercase letters, uppercase letters and special characters') : ''
    );

    return (
      <form {...formAttrs} className={classes.form}>
        {Parser(childNodes)}
        {hiddenFields.map(field => (
          <input
            type="hidden"
            key={field.name}
            name={field.name}
            value={field.value}
          />
        ))}
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2">
              {t('Create new password')}
            </Typography>
            <Typography variant="body2" className={classes.des}>
              {t('Set up your new password')}
            </Typography>
          </CardContent>
          <div className={classes.form}>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="password" className={classes.inputLabel}>
                {t('Password')}
              </InputLabel>
              <Input
                id="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                value={password}
                error={password.length > 0 ? hasError('password') : null}
                onChange={e => change(e, 'password')}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex="-1"
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                  )}
              />
              {password.length > 0 && <FormHelperText>{errorMessage('password')}</FormHelperText>}
            </FormControl>
          </div>
          <div className={classes.callAction}>
            <RegularButton text={t('Cancel')} href={linkWithLang('/doctor/sign-in')} />
            <SubmitButton isValid={isValid()} text={t('Save')} />
          </div>
        </Card>
      </form>
    );
  }
}

CreateNewPasswordForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(CreateNewPasswordForm));
