import React from 'react';
import IntroductionComponent from '../components/introductionsLanding';

function Introduction() {
  return (
    <>
      <IntroductionComponent />
    </>
  );
}

export default Introduction;
