import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ForgotPasswordForm from './ForgotPasswordForm';

const validationSchema = t => Yup.object({
  email: Yup.string().required(t('Enter your email'))
    .matches(/^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/, t('Invalid email format')),
});

const ForgotPassword = ({ enqueueSnackbar, setIsLoading }) => {
  const values = { email: '' };
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema(t)}
    >
      {formikProps => (
        <ForgotPasswordForm
          {...formikProps}
          enqueueSnackbar={enqueueSnackbar}
          setIsLoading={setIsLoading}
        />
      )}
    </Formik>
  );
};

ForgotPassword.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default ForgotPassword;
