import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import SectionContact from './SectionContact';
import FooterIntroduction from '../introductionsLanding/FooterIntroduction';

const styles = {
  wrapper: {
    position: 'relative',
  },
};

function ContactUs({ classes }) {
  return (
    <div className={classes.wrapper}>
      <SectionContact />

      <FooterIntroduction />
    </div>
  );
}

ContactUs.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ContactUs);
