import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SubmitButton from '../common/SubmitButton';
import linkWithLang from '../../helpers/LinkWithLanguage';
import GetCurrentLanguage from '../../helpers/GetCurrentLanguage';


const lang = GetCurrentLanguage();

const styles = theme => ({
  root: {
    minWidth: 275,
    maxWidth: 500,
    padding: theme.spacing(2),
  },
  des: {
    marginTop: theme.spacing(2),
    '@media only screen and (device-width: 1024px)': {
      fontSize: '12px !important',
    },
  },
  callAction: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  signIn: {
    flex: '1',
    marginLeft: '10px',
    '@media screen and (min-width:1200px)': {
      '&> br': {
        display: 'none',
      },
    },
  },
  form: { padding: theme.spacing(2) },
  inputLabel: { fontSize: '1rem' },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  errorMessage: {
    color: '#dc3545',
    whiteSpace: 'pre-wrap',
  },
  card_container: {
    paddingRight: 0,
  },
  required: {
    color: 'red',
    fontSize: '12px',
  },
});

class CreateAccountForm extends Component {
  state = {
    showPassword: false,
    formAttrs: {},
    isLoading: false,
  };

  componentDidMount = () => {
    const originalForm = document.getElementById('doctor-sign-up-form');
    if (!originalForm) { return; }
    const formAttrs = ['action', 'acceptCharset', 'method'].reduce((obj, val) => {
      const transformObj = obj;
      transformObj[val] = originalForm[val];
      return transformObj;
    }, {});
    this.setState({
      formAttrs,
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleClickSubmit = () => {
    this.setState({ isLoading: true });
  };

  render() {
    const {
      values: { email, password },
      errors,
      touched,
      handleChange,
      setFieldTouched,
      classes,
      t,
    } = this.props;
    const isValid = () => {
      let valid = false;

      if (!_isEmpty(email) || !_isEmpty(password)) {
        valid = _isEmpty(errors);
      }
      return valid;
    };

    const hiddenFields = [
      { name: 'doctor[email]', value: email },
      { name: 'doctor[password]', value: password },
    ];

    const change = (e, name) => {
      e.persist();
      handleChange(e);
      setFieldTouched(name, true, false);
    };

    const {
      showPassword, formAttrs, isLoading,
    } = this.state;

    const hasError = field => (
      (touched[field] && Boolean(errors[field]))
        || (Boolean(errors[field]) && Boolean(!errors.passwordConfirmation))
    );

    const errorMessage = field => (
      (touched[field] || Boolean(!errors.passwordConfirmation)) ? errors[field] : ''
    );

    return (
      <form {...formAttrs} className={classes.form}>
        {hiddenFields.map(field => (
          <input
            type="hidden"
            key={field.name}
            name={field.name}
            value={field.value}
          />
        ))}
        <Card className={classes.root} variant="outlined">
          <CardContent className={classes.card_container}>
            <Typography variant="h5" component="h2">
              {t('Create account')}
            </Typography>
            <Typography variant="body2" className={classes.des}>
              {t('sign_up.intro_3')}
            </Typography>
            <Typography variant="body2" className={classes.des}>
              <em className={classes.required}>{t('* Required item')}</em>
            </Typography>
          </CardContent>
          <div className={classes.form}>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="email" className={classes.inputLabel}>
                {t('Work email address')}
              </InputLabel>
              <Input
                id="email"
                value={email}
                error={hasError('email')}
                onChange={e => change(e, 'email')}
              />
              <FormHelperText className={classes.errorMessage}>{errorMessage('email')}</FormHelperText>
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="password" className={classes.inputLabel}>
                {t('Password')}
              </InputLabel>
              <Input
                id="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                value={password}
                error={password.length > 0 ? hasError('password') : null}
                onChange={e => change(e, 'password')}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex="-1"
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                  )}
              />
              {password.length > 0 && <FormHelperText className={classes.errorMessage}>{errorMessage('password')}</FormHelperText>}
            </FormControl>
          </div>
          <div className={classes.callAction}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Link href={linkWithLang('/doctor/sign-in')} className={classes.signIn}>
                {lang === 'jp' && '登録済みのため、'}
                <br />
                {t('sign_in_2')}
              </Link>
            </div>
            <SubmitButton isValid={isValid()} text={t('Next')} onClick={this.handleClickSubmit} />
          </div>
        </Card>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    );
  }
}

CreateAccountForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(CreateAccountForm));
