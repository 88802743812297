import React from 'react';
import ContactComponent from '../components/contactus/ContactUs';

function ContactUs() {
  return (
    <>
      <ContactComponent />
    </>
  );
}

export default ContactUs;
