import { createTheme } from '@material-ui/core/styles';

const DoctorPlatformTheme = createTheme({
  typography: {
    fontFamily: 'Hiragino Sans, sans-serif',
    fontType: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
  },
  overrides: {
    MuiAppBar: {
      positionSticky: { top: 80 },
      colorDefault: {
        zIndex: 1000,
      },
      root: { zIndex: 1000 },
    },
    MuiLink: {
      root: { color: '#3aa3e3' },
    },
    MuiButton: {
      root: {
        borderRadius: 5,
        textTransform: 'none',
      },
      contained: {
        '&:hover': { backgroundColor: '#e0e0e0' },
      },
      containedPrimary: {
        backgroundColor: '#2C62D6',
        borderRadius: 4,
        width: 114,
        height: 44,
      },
    },
    MuiSnackbarContent: {
      message: { fontSize: 14 },

      '@media (max-width: 768px)': {
        message: { fontSize: 12 },
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: 300,
        fontSize: '1.25rem',
      },
      body2: {
        color: '#466888',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 18,
        fontWeight: 300,
      },
    },
    MuiInputLabel: {
      outlined: {
        fontSize: '1.25rem',
        zIndex: 'auto',
      },
      root: {
        fontSize: '1rem',
      },
    },
    MuiTableCell: {
      root: {
        borderTop: '1px solid #C7C7C7',
        borderBottom: 'none',
        '&:last-child': {
          borderRight: '1px solid #C7C7C7',
        },
      },
      paddingCheckbox: { borderLeft: '1px solid #C7C7C7' },
      stickyHeader: { backgroundColor: 'none' },
    },
    MuiFilledInput: {
      root: {
        borderRadius: 4,
        height: 24,
      },
    },
    MuiTabs: {
      flexContainer: { height: '100%' },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        position: 'absolute',
        top: '64px',
        right: '10%',
      },
      content: { margin: '32px 0px' },
      root: {
        '&:hover': { cursor: 'default !important' },
      },
    },
    MuiTablePagination: {
      root: {
        backgroundColor: '#fafafa',
      },
      toolbar: {
        paddingRight: 80,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      ip5: 321,
      ipx: 376,
      iplush: 416,
      sm: 600,
      ipad: 769,
      md: 960,
      ipadpro: 1025,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default DoctorPlatformTheme;
