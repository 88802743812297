import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
  root: {
    marginTop: theme.spacing(8),
  },
});

const SnackbarContainer = ({ classes, ...rest }) => (
  <SnackbarProvider maxSnack={3} classes={{ root: classes.root }} {...rest} />
);

SnackbarContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(style)(SnackbarContainer);
