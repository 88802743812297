import React from 'react';
import { render } from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import App from '../components/App';
import './i18n';

require('jquery');
require('gritter/js/jquery.gritter.js');

document.addEventListener('DOMContentLoaded', () => {
  const divElement = document.createElement('div');
  divElement.style.height = '100%';

  render(
    <App />,
    document.body.appendChild(divElement),
  );
});
