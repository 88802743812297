import LOGO from '../../assets/images/logo.svg';

export const LANDING_BACKGROUND = 'https://doctor-platform.s3-ap-southeast-1.amazonaws.com/background_homepage.png';
export const DOCTOR_PLATFORM_LOGO = LOGO;
export const BAYER_LOGO = 'https://doctor-platform.s3-ap-southeast-1.amazonaws.com/bayer-logo.png';
export const BOEHRINGER_LOGO = 'https://doctor-platform.s3-ap-southeast-1.amazonaws.com/boehringer-logo.png';
export const ASTRAZENECA_LOGO = 'https://doctor-platform.s3-ap-southeast-1.amazonaws.com/astrazeneca-logo.png';
export const LILLY_LOGO = 'https://doctor-platform.s3-ap-southeast-1.amazonaws.com/lilly-logo.png';
export const MERCK_LOGO = 'https://doctor-platform.s3-ap-southeast-1.amazonaws.com/merck-logo.png';
export const MSD_LOGO = 'https://doctor-platform.s3-ap-southeast-1.amazonaws.com/msd-logo.png';
export const PFIZER_LOGO = 'https://doctor-platform.s3-ap-southeast-1.amazonaws.com/pfizer-logo.png';
export const USER_ACCOUNT_BG = 'https://doctor-platform.s3-ap-southeast-1.amazonaws.com/User+Account+Background.png';
export const PERSONAL_EMAIL_REGXP = /^([\w-.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!outlook.com)(?!zoho.com)(?!aol.com)(?!mail.com)(?!yandex.com)(?!yandex.com)(?!inbox.com)(?!gmx.com)(?!icloud.com)(?!aim.com)(?!qq.com)([\w-]+\.)+[\w-]{2,4})?$/;
export const MOMENT_DATETIME = 'MM YYYY';
export const PRIMARY_COLOR = '#f16567';
export const HOVER_PRIMARY_COLOR = '#f17979';
export const SECONDARY_COLOR = '#89cff0';
export const HOVER_SECONDARY_COLOR = '#4682b4';
