import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = () => ({
  root: {
    textTransform: 'none',
    width: 114,
    height: 44,
  },
});

const RegularButton = ({ classes, text, ...rest }) => (
  <Button
    {...rest}
    variant="contained"
    className={classes.root}
    disableElevation
  >
    {text}
  </Button>
);

RegularButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  text: PropTypes.string.isRequired,
};

export default withStyles(styles)(RegularButton);
