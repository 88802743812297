import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import DiseaseSpecialitiesField from './DiseaseSpecialitiesField';
import PushSnackbarMessage from '../../helpers/PushSnackbarMessage';
import AxiosHeaders from '../../helpers/AxiosHeaders';
import linkWithLang from '../../helpers/LinkWithLanguage';

const styles = theme => ({
  root: {
    width: '100%',
    minWidth: 275,
    maxWidth: 500,
    maxHeight: window.innerHeight - theme.spacing(21),
    overflowY: 'scroll',
    padding: theme.spacing(2),
  },
  des: { marginTop: theme.spacing(2) },
  email: { marginLeft: theme.spacing(1) },
  backBtn: {
    '&:hover': {
      background: 'none',
      color: '#000',
    },
  },
  form: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  callAction: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
  diseaseSpecialities: {
    marginTop: theme.spacing(3),
  },
  required: {
    color: 'red',
    fontSize: '12px',
  },
});

const DoctorWorkExperience = (props) => {
  const {
    classes, enqueueSnackbar, setIsLoading,
  } = props;
  const {
    registration_info: registrationInfo, doctor_types: doctorTypes, prefectures,
    workplace_types: workplaceTypes, disease_specialities: initDiseaseSpecialities,
    profile_registration: initialProfileInfos,
  } = window.gon;
  const { email: doctorEmail } = registrationInfo;
  const [workExperienceInfos, setWorkExperienceInfos] = useState(
    {
      workplaceType: null,
      workplaceName: null,
      prefecture: null,
      city: null,
      postalCode: null,
      workplaceAddress: null,
      workplacePhone: null,
      doctorType: null,
      diseaseSpecialities: [],
    },
  );
  const [specStep, setSpecStep] = useState(false);
  const { t } = useTranslation();
  const handleChangeWorkExperienceInfos = (value, type) => {
    setWorkExperienceInfos(prevState => ({ ...prevState, [type]: value }));
  };
  const enableSubmit = () => {
    const {
      workplaceType, workplaceName, prefecture, city, postalCode, diseaseSpecialities,
      workplacePhone,
    } = workExperienceInfos;
    return (!specStep
      && !_isEmpty(workplaceType) && !_isEmpty(workplaceName)
      && !_isEmpty(prefecture) && !_isEmpty(city) && !_isEmpty(postalCode)
      && !_isEmpty(workplacePhone)) || (specStep && !_isEmpty(diseaseSpecialities));
  };
  const handleUpdateWorkExperience = () => {
    if (!specStep) {
      setSpecStep(true);
    } else {
      setIsLoading(true);
      const {
        workplaceName, workplaceType, prefecture, city, postalCode, workplaceAddress,
        workplacePhone, doctorType, diseaseSpecialities,
      } = workExperienceInfos;
      const {
        id,
      } = initialProfileInfos;

      if (!_isNull(id)) {
        const submitData = Object.assign({});
        if (!_isEmpty(workplaceName)) {
          submitData.workplace_name = workplaceName;
        }
        if (!_isEmpty(workplaceType)) {
          submitData.workplace_type = workplaceType;
        }
        if (!_isEmpty(workplaceAddress)) {
          submitData.workplace_address = workplaceAddress;
        }
        if (!_isEmpty(workplacePhone)) {
          submitData.workplace_phone = workplacePhone;
        }
        if (!_isEmpty(city)) {
          submitData.city = city;
        }
        if (!_isEmpty(postalCode)) {
          submitData.postal_code = postalCode;
        }
        if (!_isEmpty(doctorType)) {
          submitData.doctor_type = doctorType;
        }
        if (!_isEmpty(prefecture)) {
          submitData.prefecture_id = prefecture.id;
        }
        if (!_isEmpty(diseaseSpecialities)) {
          submitData.disease_speciality_ids = diseaseSpecialities.map(spec => spec.id);
          const subList = diseaseSpecialities[0].sub_speciality;
          if (!_isEmpty(subList) && subList.length > 0) {
            submitData.sub_speciality_ids = subList.map(sub => sub.id);
          }
        } else {
          PushSnackbarMessage(enqueueSnackbar, t('You need to input Disease specialities'), 'error');
          return;
        }
        submitData.is_current = true;
        submitData.chosen_current = true;
        axios({
          url: linkWithLang(`doctors/${id}`),
          method: 'PUT',
          headers: AxiosHeaders,
          data: {
            doctor: {
              work_experiences_attributes: { 0: submitData },
            },
          },
        }).then(() => {
          PushSnackbarMessage(enqueueSnackbar, t('Work experience created succesfully'), 'success');
          setIsLoading(false);
          window.location.reload();
        }).catch(({ response: { data } }) => {
          setIsLoading(false);
          if (_isArray(data.message)) {
            data.message.map(me => PushSnackbarMessage(enqueueSnackbar, me, 'error'));
          } else {
            PushSnackbarMessage(enqueueSnackbar, data.message, 'error');
          }
        });
      } else {
        PushSnackbarMessage(enqueueSnackbar, t('User ID not recognized'), 'error');
        setIsLoading(false);
      }
    }
  };
  const handleBackProfileUpdate = () => {
    const location = window.location.href;
    window.location.href = linkWithLang(`${location}&&step=1`);
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <IconButton aria-label="delete" size="small" className={classes.backBtn} onClick={handleBackProfileUpdate}>
          <ArrowBackIcon fontSize="inherit" />
          <Typography variant="body2" className={classes.email}>{doctorEmail}</Typography>
        </IconButton>
        <Typography variant="body2" className={classes.des}>
          <em className={classes.required}>{t('* Required item')}</em>
        </Typography>
      </CardContent>
      <div className={classes.form}>
        <Grid container spacing={2}>
          {!specStep && (
          <Grid item xs={12}>
            <Autocomplete
              options={workplaceTypes}
              getOptionLabel={option => t(option)}
              size="small"
              disableClearable
              noOptionsText={t('No options')}
              renderInput={params => (
                <TextField {...params} label={t('Workplace - type of facility')} required />
              )}
              onChange={(_, value) => handleChangeWorkExperienceInfos(value, 'workplaceType')}
            />
          </Grid>
          )}
          {!specStep && (
          <Grid item xs={12}>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="workplace name">
                {t('Workplace name')}
              </InputLabel>
              <Input
                id="workplace name"
                onChange={e => handleChangeWorkExperienceInfos(e.target.value, 'workplaceName')}
              />
            </FormControl>
          </Grid>
          )}
          {!specStep && (
          <Grid item xs={12}>
            <FormControl margin="dense" fullWidth required>
              <InputLabel htmlFor="Postal Code">
                {t('Postal Code')}
              </InputLabel>
              <Input
                id="postal code"
                onChange={e => handleChangeWorkExperienceInfos(e.target.value, 'postalCode')}
              />
            </FormControl>
          </Grid>
          )}
          {!specStep && (
          <Grid item xs={12}>
            <Autocomplete
              options={prefectures}
              getOptionLabel={option => t(option.name)}
              size="small"
              disableClearable
              noOptionsText={t('No options')}
              renderInput={params => (
                <TextField {...params} label={t('Prefecture (Location)')} required />
              )}
              onChange={(_, value) => handleChangeWorkExperienceInfos(value, 'prefecture')}
            />
          </Grid>
          )}
          {!specStep && (
          <Grid item xs={12}>
            <FormControl margin="dense" fullWidth required>
              <InputLabel htmlFor="City">
                {t('City')}
              </InputLabel>
              <Input
                id="City"
                onChange={e => handleChangeWorkExperienceInfos(e.target.value, 'city')}
              />
            </FormControl>
          </Grid>
          )}
          {!specStep && (
          <Grid item xs={12}>
            <FormControl margin="dense" fullWidth required>
              <InputLabel htmlFor="workplace address">
                {t('Workplace address')}
              </InputLabel>
              <Input
                id="workplace address"
                onChange={e => handleChangeWorkExperienceInfos(e.target.value, 'workplaceAddress')}
              />
            </FormControl>
          </Grid>
          )}
          {!specStep && (
          <Grid item xs={12}>
            <FormControl margin="dense" fullWidth required>
              <InputLabel htmlFor="workplace phone number">
                {t('Workplace Telephone number')}
              </InputLabel>
              <Input
                id="workplace phone number"
                onChange={e => handleChangeWorkExperienceInfos(e.target.value, 'workplacePhone')}
              />
            </FormControl>
          </Grid>
          )}
          {/* {specStep && (
          <Grid item xs={12} className="d-none">
            <FormControl margin="dense" fullWidth required>
              <Autocomplete
                options={doctorTypes}
                getOptionLabel={option => t(option)}
                size="small"
                disableClearable
                noOptionsText={t('No options')}
                renderInput={params => (
                  <TextField {...params} label={t('Type of Doctor')} />
                )}
                onChange={(_, value) => handleChangeWorkExperienceInfos(value, 'doctorType')}
              />
            </FormControl>
          </Grid>
          )} */}
          {specStep && (
          <Grid item xs={12} className={classes.diseaseSpecialities}>
            <FormControl margin="dense" fullWidth>
              <DiseaseSpecialitiesField
                options={initDiseaseSpecialities}
                diseaseSpecialities={workExperienceInfos.diseaseSpecialities}
                handleChangeWorkExperienceInfos={handleChangeWorkExperienceInfos}
              />
            </FormControl>
          </Grid>
          )}
        </Grid>
      </div>
      <div className={classes.callAction}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleUpdateWorkExperience}
          disabled={!enableSubmit()}
        >
          {t('Next')}
        </Button>
      </div>
    </Card>
  );
};

DoctorWorkExperience.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default withStyles(styles)(DoctorWorkExperience);
